import React,{useState,useEffect} from 'react'
import { SharedService } from '../../Shared/Shared.service';
import {AuthService} from '../../Shared/Auth.service'
import {IssuerTokenAdminService} from '../IssuerTokenAdmin.service'
import {Row,Col,Typography,Card,Descriptions,Button, message,Table,Badge,Tabs} from 'antd'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'
const {TabPane}=Tabs
const issuerTokenAdminService=new IssuerTokenAdminService();
const authService=new AuthService();
const useUserContext = () => authService.useUserContext();
const sharedService=new SharedService();
const {Title}=Typography;


export default ()=>{
  const {userInfo, setUserInfo} = useUserContext();
  const [detailsView,setDetailsView]=useState(false)
  const columns=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetails(investor)}>DETAILS</Button>
    }
  
  ]
  const columns1=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'Status',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=>{
        return(
          <>
          {investor.verified && <span>Approved</span>}
          {investor.rejected && <span>Rejected</span>}
          </>
        )
      }
    }
  
  ]
  const [investorDetails,setCurrentInvestor]=useState()
  const [investors,setInvestors]=useState(null)  
  const [approvedAndRejectedInvestors,setInvestorsList]=useState([])
  const [submitting,setSubmitting]=useState(false)
  
  useEffect(
    ()=>{ (async ()=>{
      console.log(userInfo);
      const response=await issuerTokenAdminService.getAIVerificationRequest({company:userInfo.companyName});
      if(response.success && response.data)
      {
        console.log(response.data);
        setInvestors(response.data);
      }
     })();
    },[investors]);

    useEffect(
      ()=>{ (async ()=>{
        console.log(userInfo);
        const response=await issuerTokenAdminService.getInvestorsList({company:userInfo.companyName});
        if(response.success && response.data)
        {
          console.log(response.data);
          setInvestorsList(response.data);
        }
       })();
      },[approvedAndRejectedInvestors]);
   
  const showDetails=(investor)=>{
    setCurrentInvestor(investor)
    setDetailsView(true)
  }
  const acceptAI=async (data)=>
  {
    setSubmitting(true);
    const response=await issuerTokenAdminService.acceptAI(data);
    if(response.success && response.data)
    {
      setDetailsView(false)
    }
    else
    {
      message.error("Some error occured!");
    }
    setSubmitting(false);
  }
  const rejectAI=async (data)=>
  {
    setSubmitting(true);
    const response=await issuerTokenAdminService.rejectAI(data);
    if(response.success && response.data)
    {
      setDetailsView(false)
    }
    else
    {
      message.error("Some error occured!");
    }
    setSubmitting(false);
  }

  return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Accredited Investor Verification 
            </Title>
            <Tabs defaultActiveKey="request" centered>
              <TabPane tab={<span><PlaySquareOutlined/>Pending Accredited Investor Verification Requests </span>}>
            <div hidden={detailsView} >
            <Table dataSource={investors} columns={columns} />
            </div>
            <div hidden={!detailsView}>
            <Descriptions title='Investor Details' 
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            title="Investor Details"
            bordered>
        
             <Descriptions.Item label="First Name">{investorDetails?.firstName}</Descriptions.Item>
              <Descriptions.Item label="Last Name">{investorDetails?.lastName}</Descriptions.Item>
              <Descriptions.Item label="Date of AI verification Request">{moment(investorDetails?.dateOfSubmission).format('LLL')}</Descriptions.Item>
              <Descriptions.Item label="Contact Number">{investorDetails?.contactNumber}</Descriptions.Item>
              <Descriptions.Item label="AI Documents">{investorDetails?.aiDocument.map((document,index)=>{
                return (
                  <div>
                    {index+1}) <a href={document}>{document}</a>
                    <br/>
                  </div>
                )
              })}</Descriptions.Item>
              <Descriptions.Item label="Status"><Badge color='yellow' status="processing" text={`Waiting For Approval`}></Badge></Descriptions.Item>
            </Descriptions>
            <br/>
            <Button onClick={()=>acceptAI(investorDetails)} type='primary' loading={submitting} >Approve</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>rejectAI(investorDetails)} disabled={submitting}>Reject</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={()=>setDetailsView(false)} disabled={submitting}>Back</Button>
            </div>
            </TabPane>
            <TabPane tab={<span><ProjectOutlined/>Approved/Rejected Investors</span>} key="2">
              <Table columns={columns1} dataSource={approvedAndRejectedInvestors}></Table>
            </TabPane>
            </Tabs>

        </Card>
      </Col>
    </Row>
    
    </>
  )
}